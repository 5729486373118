import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Paper, Divider } from '@mui/material';
import { AccountCircle, Lock, Business } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


const SignUpPage = ({ onSignUp }) => {
  const [credentials, setCredentials] = useState({ username: '', password: '', organization_name: '' });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleOAuthSignUp = (provider) => {

    window.location.href = `${process.env.REACT_APP_API_URL}/pawai-backend2/${provider}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/pawai-backend2/auth/register/`, credentials, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.status === 200) {
        alert('Sign-up successful! You can now log in.');
        onSignUp();
        navigate('/');
      }
    } catch (error) {
      setError('Sign-up failed: ' + (error.response?.data?.detail || error.message));
    }

    setLoading(false);
  };

  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#f5f5f5' }}>
      <Paper elevation={3} sx={{ maxWidth: 400, padding: 4, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>Sign Up</Typography>
        {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}
        
        {/* OAuth Sign-Up Buttons */}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => handleOAuthSignUp('google')}
          sx={{ mb: 1 }}
        >
          Sign Up with Google
        </Button>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => handleOAuthSignUp('outlook')}
          sx={{ mb: 1 }}
        >
          Sign Up with Outlook
        </Button>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => handleOAuthSignUp('apple')}
          sx={{ mb: 2 }}
        >
          Sign Up with Apple
        </Button>

        <Divider sx={{ my: 2 }}>or</Divider>

        {/* Traditional Sign-Up Form */}
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', mb: 2 }}>
            <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <TextField
              label="Username"
              variant="standard"
              fullWidth
              value={credentials.username}
              onChange={(e) => setCredentials({ ...credentials, username: e.target.value })}
              required
            />
          </Box>
          
          <Box sx={{ display: 'flex', alignItems: 'flex-end', mb: 2 }}>
            <Lock sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <TextField
              label="Password"
              type="password"
              variant="standard"
              fullWidth
              value={credentials.password}
              onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
              required
            />
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'flex-end', mb: 2 }}>
            <Business sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <TextField
              label="Organization Name"
              variant="standard"
              fullWidth
              value={credentials.organization_name}
              onChange={(e) => setCredentials({ ...credentials, organization_name: e.target.value })}
              required
            />
          </Box>

          <Button 
            type="submit" 
            variant="contained" 
            color="primary" 
            fullWidth 
            disabled={loading} 
            sx={{ mt: 3, mb: 2 }}
          >
            {loading ? 'Signing Up...' : 'Sign Up'}
          </Button>
        </form>

        <Typography
          variant="body2"
          sx={{ cursor: 'pointer', color: 'primary.main' }}
          onClick={() => navigate('/')}
        >
          Already have an account? Login
        </Typography>
      </Paper>
    </Box>
  );
};

export default SignUpPage;
