import React, { useEffect, useState } from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';
import axios from 'axios';

const AccountInfoPage = () => {
  const [accountInfo, setAccountInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAccountInfo = async () => {
      const token = localStorage.getItem('access_token');
      
     
      console.log("Access token:", token);

      if (!token) {
        setError("No access token found. Please log in.");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get( `${process.env.REACT_APP_API_URL}pawai-backend2/auth/user/organization`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log("Account Info Response:", response.data);
        setAccountInfo(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching account information:", err);
        setError("Failed to load account information.");
        setLoading(false);
      }
    };

    fetchAccountInfo();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Account Information
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Name: {accountInfo?.name || 'N/A'}
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Email: {accountInfo?.email || 'N/A'}
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Username: {accountInfo?.username || 'N/A'}
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Organization: {accountInfo?.organization_name || 'N/A'}
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Subscription Status: {accountInfo?.subscription_status ? 'Active' : 'Inactive'}
      </Typography>
    </Box>
  );
};

export default AccountInfoPage;
