import React, { useState } from 'react';
import { Box, Typography, Toolbar, Button, Menu, MenuItem, AppBar } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import aiAssistantIcon from '../assets/AI_assistant.png';
import filesIcon from '../assets/files.png';
import resourcesIcon from '../assets/resources.png';
import helpIcon from '../assets/help.png';
import settingsIcon from '../assets/settings.png';

const Taskbar = ({ isLoggedIn, handleLogout }) => {
  const location = useLocation();
  const isActive = (path) => location.pathname === path;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleSettingsClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    isLoggedIn && (
      <AppBar position="static" color="default" sx={{ height: '72px', boxShadow: 'none', backgroundColor: '#ffffff' }}>
        <Toolbar>
          {[
            { label: 'AI Assistant', path: '/ai-assistant', icon: aiAssistantIcon },
            { label: 'Files', path: '/files', icon: filesIcon },
            { label: 'Resources', path: '/resources', icon: resourcesIcon },
            { label: 'Help', path: '/help', icon: helpIcon },
          ].map((navItem) => (
            <Button
              key={navItem.path}
              component={Link}
              to={navItem.path}
              sx={{
                color: isActive(navItem.path) ? 'primary.main' : 'text.primary',
                borderBottom: isActive(navItem.path) ? '2px solid #1976d2' : 'none',
                transition: 'color 0.2s, border-bottom 0.2s',
                mx: 2,
                display: 'flex',
                alignItems: 'center',
                '&:hover': { color: 'primary.main', borderBottom: '2px solid #1976d2' },
              }}
            >
              <Box
                component="img"
                src={navItem.icon}
                alt={`${navItem.label} Icon`}
                sx={{ width: 24, height: 24, mr: 1 }}
              />
              {navItem.label}
            </Button>
          ))}

          {/* Settings Button with Icon */}
          <Box
            onClick={handleSettingsClick}
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              color: 'text.primary',
              mx: 2,
            }}
          >
            <Box component="img" src={settingsIcon} alt="Settings Icon" sx={{ width: 24, height: 24, mr: 1 }} />
            <Typography variant="button" component="span">
              Settings
            </Typography>
          </Box>

          <Menu
            id="settings-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{ 'aria-labelledby': 'settings-button' }}
          >
            <MenuItem component={Link} to="/account-info" onClick={handleClose}>
              Account Information
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                handleLogout();
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    )
  );
};

export default Taskbar;
