import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, IconButton } from '@mui/material';
import PatientForm from './PatientForm';
import axios from 'axios';
import pawLogo from '../assets/Pawlogo.png';
import submitIcon from '../assets/submit.png';
import attachIcon from '../assets/attach.png';

const ChatWindow = ({ sessionId, chatMessages, onPatientDataSubmit, viewMode, handleViewMode }) => {
  const [messages, setMessages] = useState(chatMessages);
  const [greeting, setGreeting] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [formData, setFormData] = useState({
    pet_name: '',
    species: '',
    breed: '',
    age: '',
    weight: '',
    sex: '',
    concerns: '',
  });
  const [conversationTitle, setConversationTitle] = useState('');
  const [pdfFile, setPdfFile] = useState(null);

  useEffect(() => {
    const currentHour = new Date().getHours();
    setGreeting(currentHour < 12 ? 'Good morning' : currentHour < 18 ? 'Good afternoon' : 'Good evening');

    const fetchOrganizationName = async () => {
      const token = localStorage.getItem('access_token');
      if (token) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/pawai-backend2/auth/user/organization`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const orgName = response.data.organization_name;
          setOrganizationName(orgName);
          localStorage.setItem('organization_name', orgName);
        } catch (error) {
          console.error("Error fetching organization name:", error);
        }
      } else {
        console.warn("No access token found.");
        setOrganizationName('Your Organization');
      }
    };

    fetchOrganizationName();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      setPdfFile(file);
    } else {
      alert('Only PDF files are allowed.');
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!conversationTitle) {
      alert('Please enter a message to start the session.');
      return;
    }
  
    const newSessionId = await onPatientDataSubmit(formData, conversationTitle, pdfFile);
    if (newSessionId) {
      handleViewMode('chat');
    } else {
      console.error('Session creation failed.');
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',  
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        padding: '24px',
        gap: '16px',
        borderRadius: '16px',
        boxShadow: '0px 4px 8px -2px rgba(0, 0, 0, 0.1)',
        boxSizing: 'border-box',
        overflow: 'hidden',
        margin: '0 auto'
      }}
    >
      {/* Greeting and Info Section */}
      <Box
        sx={{
          height: '10%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Box component="img" src={pawLogo} alt="Paw Logo" sx={{ width: '36px', height: '36px' }} />
          <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: 600, color: '#000626' }}>
            {greeting}, {organizationName}!
          </Typography>
        </Box>
        <Typography variant="subtitle1" sx={{ fontSize: '1.125rem', fontWeight: 500, textAlign: 'center', marginTop: '10px', color: '#000933' }}>
          To get started enter your patient's information below.
        </Typography>
      </Box>

      {viewMode === 'patientForm' && (
        <>
          {/* PatientForm Section */}
          <Box 
  sx={{ 
    height: '55%', 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    overflow: 'auto',
    maxWidth: '800px', 
    width: '100%', 
    margin: '0 auto' 
  }}
>
  <PatientForm formData={formData} setFormData={setFormData} />
</Box>
          {/* Paw AI Medical Assistant Form */}
        {/* Paw AI Medical Assistant Form */}
<Box
  component="form"
  onSubmit={handleFormSubmit}
  sx={{
    height: { xs: 'auto', md: '30%' }, // Make height responsive
    maxWidth: '800px', 
    width: '100%',
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    padding: { xs: '8px', md: '12px' }, // Smaller padding on small screens
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    border: '1px solid rgba(0, 17, 102, 0.2)',
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    boxSizing: 'border-box',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    marginBottom:'0px',
  }}
>
  <Typography 
    sx={{ 
      fontSize: '16px', 
      fontWeight: 'normal', 
      color: '#333', 
      alignSelf: 'flex-start' 
    }}
  >
    Paw AI Medical Assistant
  </Typography>

  <TextField
    fullWidth
    label="How can Paw AI help you today?"
    value={conversationTitle}
    onChange={(e) => setConversationTitle(e.target.value)}
    variant="outlined"
    required
    multiline
    rows={3}
    InputProps={{
      endAdornment: (
        <IconButton
          type="submit"
          color="primary"
          sx={{
            width: '36px',
            height: '36px',
            backgroundColor: '#4C64D9',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '8px',
            position: 'absolute',
            right: { xs: '4px', md: '8px' },
            bottom: { xs: '4px', md: '8px' }, 
          }}
        >
          <Box component="img" src={submitIcon} alt="Submit Icon" sx={{ width: '36px', height: '36px' }} />
        </IconButton>
      ),
      sx: {
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
        paddingRight: { xs: '40px', md: '48px' },
      },
    }}
    sx={{
      height: { xs: 'auto', md: '109px' }, 
      borderRadius: '8px',
      border: '1px solid transparent',
      backgroundColor: '#FFFFFF',
    }}
  />

  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: '#EFF1FD',
      padding: '8px',
      borderRadius: '8px',
      border: '1px dashed #bbb',
      width: '98%',
      flexWrap: 'wrap', 
      gap: { xs: '4px', md: '0' }, 
    }}
  >
    <Typography variant="body2" sx={{ color: '#555' }}>Upload documents & images for more accurate results.</Typography>
    <Button
      variant="text"
      component="label"
      startIcon={<Box component="img" src={attachIcon} alt="Attach Icon" sx={{ width: '16px', height: '16px' }} />}
      sx={{ color: '#555', textTransform: 'none', fontSize: '12px' }}
    >
      Add Content
      <input type="file" accept="application/pdf" hidden onChange={handleFileChange} />
    </Button>
  </Box>

  {pdfFile && (
    <Typography variant="body2" sx={{ color: '#007bff', marginTop: '4px', fontSize: { xs: '12px', md: '14px' } }}>
      Uploaded file: {pdfFile.name}
    </Typography>
  )}
</Box>

        </>
      )}
    </Box>
  );
};

export default ChatWindow;
