import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Box, Typography } from '@mui/material';
import axios from 'axios';

const PaymentSuccess = ({ onCheckSubscription }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      try {
    
        await onCheckSubscription();
  
        const token = localStorage.getItem("access_token");
        if (token) {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/pawai-backend2/auth/user/organization`, {
            headers: { Authorization: `Bearer ${token}` },
          });
  
          if (response.data.has_subscription) {
        
            navigate('/ai-assistant');
          } else {
           
            const interval = setInterval(async () => {
              try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/pawai-backend2/auth/user/organization`, {
                  headers: { Authorization: `Bearer ${token}` },
                });
  
                if (res.data.has_subscription) {
                  clearInterval(interval);
                  navigate('/ai-assistant');
                }
              } catch (error) {
                console.error("Error checking subscription status in interval:", error);
              }
            }, 2000);
  
         
            return () => clearInterval(interval);
          }
        }
      } catch (error) {
        console.error("Error checking subscription status:", error);
      }
    };
  
    checkSubscriptionStatus();
  }, [onCheckSubscription, navigate]);
  
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center'
      }}
    >
      <CircularProgress />
      <Typography variant="h5" sx={{ mt: 2 }}>
        Payment successful! Redirecting...
      </Typography>
    </Box>
  );
};

export default PaymentSuccess;
