import React, { useState } from 'react';
import { TextField, Typography, Box, IconButton, Button } from '@mui/material';
import submitIcon from '../assets/submit.png';
import attachIcon from '../assets/attach.png';

const ChatInput = ({ onSend }) => {
  const [input, setInput] = useState('');
  const [file, setFile] = useState(null);

  const handleSend = () => {
    if (input.trim() || file) {
      onSend(input, file);
      setInput('');
      setFile(null);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
      setFile(selectedFile);
    } else {
      alert('Only PDF files are allowed.');
    }
  };

  return (
    <Box sx={{ width: '800px', margin: '0 auto' }}> {/* Centering and setting width to 800px */}
      <Typography
        variant="body2"
        sx={{
          color: '#555',
          textAlign: 'right',
          fontSize: '14px',
          mb: 1,
        }}
      >
        Paw AI can make mistakes, please double-check responses.
      </Typography>

      <TextField
        fullWidth
        label="Reply to Paw AI..."
        variant="outlined"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') handleSend();
        }}
        multiline
        rows={3}
        InputProps={{
          endAdornment: (
            <IconButton
              type="button"
              color="primary"
              onClick={handleSend}
              sx={{
                width: '40px',
                height: '40px',
                borderRadius: '8px',
                backgroundColor: '#4C64D9',
                position: 'absolute',
                right: '8px',
                bottom: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box
                component="img"
                src={submitIcon}
                alt="Submit Icon"
                sx={{ width: '36px', height: '36px' }}
              />
            </IconButton>
          ),
          sx: {
            backgroundColor: '#FFFFFF',
            borderRadius: '8px',
            paddingRight: '48px',
          },
        }}
        sx={{
          height: '109px',
          borderRadius: '8px',
          border: '1px solid transparent',
          backgroundColor: '#FFFFFF',
        }}
      />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: '#EFF1FD',
          padding: '8px',
          borderRadius: '8px',
          border: '1px dashed #bbb',
          mt: 1,
        }}
      >
        <Typography variant="body2" sx={{ color: '#000626' }}>
          Upload documents & images for more accurate results.
        </Typography>
        <Button
          variant="text"
          startIcon={<Box component="img" src={attachIcon} alt="Attach Icon" sx={{ width: '18px', height: '18px' }} />}
          sx={{ color: '#555', textTransform: 'none', fontSize: '14px' }}
          component="label"
        >
          Add Content
          <input
            type="file"
            hidden
            onChange={handleFileChange}
            accept="application/pdf"
          />
        </Button>
      </Box>

      {/* Display selected file name */}
      {file && (
        <Typography variant="body2" sx={{ color: '#007bff', marginTop: '8px' }}>
          Attached file: {file.name}
        </Typography>
      )}
    </Box>
  );
};

export default ChatInput;
