import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import correctIcon from '../assets/correct.png';

const PaymentPage = () => {
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [userId, setUserId] = useState(null);  

 
  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/subscriptions/subscriptions`);
        setSubscriptions(response.data);
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
      }
    };

    const fetchUserId = async () => {
      try {
        const token = localStorage.getItem("access_token");
        if (!token) {
          console.error("No access token found in localStorage");
          return;
        }
    
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/pawai-backend2/auth/user/organization`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
    
    
        if (response.data.id) {
          setUserId(response.data.id);  
          console.log("User ID fetched successfully:", response.data.id);
        } else {
          console.error("User ID not found in response:", response.data);
        }
      } catch (error) {
        console.error("Error fetching user ID:", error);
      }
    };
    
    

    fetchSubscriptions();
    fetchUserId();
  }, []);

  const handlePayment = async (subscriptionId) => {
    if (!userId) {
      console.error("User ID not found, cannot proceed with payment.");
      return;
    }

    setLoading(true);
    try {
      console.log("Attempting to create checkout session with user_id:", userId);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/subscriptions/auth/create-checkout-session/`,
        new URLSearchParams({
          user_id: userId,  // Use the user ID
          subscription_id: subscriptionId,
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      window.location.href = response.data.url;  
    } catch (error) {
      console.error("Error creating checkout session:", error);
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        width: '550px',
        maxWidth: '100%',
        height: '600px',
        padding: '48px',
        paddingLeft: '53px', 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '24px',
        backgroundColor: '#FFFFFF',
        borderRadius: '16px',
        border: '1px solid rgba(0, 17, 102, 0.1)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      }}
    >
      {/* Remaining UI Code */}
      <Box sx={{ width: '100%', paddingLeft: '35px', paddingRight: '40px' }}>
        <Typography
          variant="h4"
          sx={{
            paddingY: '16px',
            fontFamily: 'inter',
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: '32px',
            letterSpacing: '0.5px',
            textAlign: 'left',
            borderBottom: '1px solid rgba(0, 17, 102, 0.1)',
            color: '#000',
            marginTop: '20px',
          }}
        >
          Unlock Paw AI’s Lab Assistant
        </Typography>

        <Typography
          sx={{
            paddingY: '16px',
            fontFamily: 'inter',
            fontSize: '24px',
            fontWeight: 400,
            lineHeight: '32px',
            color: '#000',
            textAlign: 'left',
            borderBottom: '1px solid rgba(0, 17, 102, 0.1)',
            mb: 4,
          }}
        >
          Subscribe to gain access to our advanced AI assistant and premium features.
        </Typography>

        <Box sx={{ textAlign: 'left' }}>
          <Typography sx={{ fontSize: '16px', color: '#333', mb: -2 }}>
            *One-Time Fee
          </Typography>
          <Typography
            variant="h2"
            sx={{
              width: '190px',
              height: '86px',
              fontWeight: 'bold',
              fontSize: '48px',
              color: '#333',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'left',
            }}
          >
            $75
          </Typography>
        </Box>

        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => handlePayment(subscriptions[0]?.id)}
          disabled={loading}
          sx={{
            width: '520px',
            height: '72px',
            padding: '16px',
            fontSize: '18px',
            fontWeight: 'bold',
            backgroundColor: '#4C63D9',
            color: '#FFFFFF',
            borderRadius: '16px',
            '&:hover': {
              backgroundColor: '#3B50B1',
            },
            mt: 2,
          }}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Upgrade Now!'}
        </Button>

        <Box sx={{ mt: 2, marginTop: '70px' }}>
          {['Feature or benefit when upgrading', 'Feature or benefit when upgrading', 'Feature or benefit when upgrading'].map((feature, index) => (
            <Typography key={index} variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: '8px', color: '#333', mb: 1 }}>
              <Box component="img" src={correctIcon} alt="correct icon" sx={{ width: '28px', height: '28px' }} />
              {feature}
            </Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentPage;
