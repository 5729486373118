import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { Box, Typography, CircularProgress, List, ListItem } from '@mui/material';
import ChatInput from './ChatInput';

const ChatBox = ({ sessionId, pdfFile }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const messageEndRef = useRef(null);

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const fetchMessages = useCallback(async () => {
    const token = localStorage.getItem('access_token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/conversations/${sessionId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMessages(response.data);

      const latestMessage = response.data[response.data.length - 1];
      if (latestMessage && latestMessage.sender === 'user') {
        await queryAI(latestMessage.content, pdfFile);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
      setError('Failed to fetch messages.');
    }
  }, [sessionId, pdfFile]);

  useEffect(() => {
    if (sessionId) {
      fetchMessages();
    }
  }, [sessionId, fetchMessages]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSend = async (inputMessage, file = pdfFile) => {
    if (!inputMessage.trim() && !file) return;

    const userMessage = { session_id: sessionId, content: inputMessage, sender: 'user' };
    setMessages((prev) => [...prev, userMessage]);
    await saveMessage(userMessage);

    await queryAI(inputMessage, file);
  };

  const queryAI = async (question, file) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('question', question);
      formData.append('session_id', sessionId);

      if (file instanceof File) {
        formData.append('file', file);
      }

      const token = localStorage.getItem('access_token');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/rag/`, formData, {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
      });

      const aiMessage = {
        session_id: sessionId,
        content: response.data.response?.content?.content || 'No valid response from AI.',
        sender: 'AI',
      };

      setMessages((prev) => [...prev, aiMessage]);
      await saveMessage(aiMessage);
    } catch (error) {
      console.error('Error fetching AI response:', error);
      setError('Failed to fetch AI response.');
    } finally {
      setLoading(false);
    }
  };

  const saveMessage = async (message) => {
    const token = localStorage.getItem('access_token');
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/conversations/`, message, {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
      });
    } catch (error) {
      console.error('Error saving message:', error);
      setError('Failed to save the message.');
    }
  };

  const renderMessageContent = (content) => {
    const lines = content.split('\n');
    let currentNumber = 0; // To track and generate correct numbering

    return (
      <Box sx={{ margin: '16px 0' }}>
        {lines.map((line, index) => {
          // Detect section headings or numbered list items (e.g., "1. Anemia:")
          if (/^\d+\.\s/.test(line.trim()) || /^\*\*.*\*\*:$/.test(line)) {
            currentNumber += 1; // Increment numbering correctly
            const formattedLine = line.replace(/^\d+\.\s|\*\*/g, ''); // Remove existing numbering and "**"
            return (
              <Typography
                key={index}
                sx={{
                  fontWeight: 'bold',
                  fontSize: '1.1rem',
                  marginBottom: '8px',
                  color: '#444',
                }}
              >
                {`${currentNumber}. ${formattedLine.trim()}`}
              </Typography>
            );
          }

          // Detect bullet points (e.g., "- Decreased red blood cell count...")
          if (line.trim().startsWith('-')) {
            return (
              <Typography
                key={index}
                sx={{
                  fontSize: '1rem',
                  marginLeft: '24px',
                  marginBottom: '8px',
                  color: '#555',
                  whiteSpace: 'pre-line',
                }}
              >
                {line.replace(/^- /, '').trim()} {/* Remove leading '-' */}
              </Typography>
            );
          }

          // General text (e.g., the concluding paragraph or introductory text)
          if (line.trim()) {
            return (
              <Typography
                key={index}
                sx={{
                  fontSize: '1rem',
                  marginBottom: '12px',
                  color: '#555',
                  whiteSpace: 'pre-line',
                }}
              >
                {line.trim()}
              </Typography>
            );
          }

          return null; // Ignore empty lines
        })}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        padding: '24px',
        gap: '16px',
        borderRadius: '16px',
        boxShadow: '0px 4px 8px -2px rgba(0, 0, 0, 0.1)',
        boxSizing: 'border-box',
        overflow: 'hidden',
        margin: '0 auto',
      }}
    >
      {/* Messages List */}
      <Box sx={{ flexGrow: 1, overflowY: 'auto', padding: '16px', maxWidth: '800px', margin: '0 auto' }}>
        <List>
          {messages.map((msg, index) => (
            <ListItem
            key={index}
            sx={{
              maxWidth: '80%', // Maximum width of the chat bubble relative to the container
              width: 'fit-content', // Adjusts to the content size
              borderRadius: '12px', // Rounded corners for a smoother appearance
              padding: 1,
              marginBottom: 3, // Space between bubbles
              alignSelf: msg.sender === 'AI' ? 'flex-start' : 'flex-end',
              background: msg.sender === 'AI'
                ? '#EFF1FD'
                : 'linear-gradient(180deg, rgba(225, 183, 87, 0) -17.45%, rgba(225, 183, 87, 0.08) 100%)',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              textAlign: 'left',
              ml: msg.sender === 'AI' ? '10px' : 'auto',
              mr: msg.sender === 'AI' ? 'auto' : '10px',
              wordWrap: 'break-word', // Ensure long words or URLs break to fit inside the bubble
            }}
          >
            {renderMessageContent(msg.content)}
          </ListItem>
          
          ))}
          {loading && <CircularProgress />}
          {error && <Typography color="error">{error}</Typography>}
          <div ref={messageEndRef}></div>
        </List>
      </Box>

      {/* Chat Input */}
      <ChatInput onSend={handleSend} />
    </Box>
  );
};

export default ChatBox;
