import React from 'react';
import { Box, Typography, Button, TextField, List, ListItem, Divider, InputAdornment, Link } from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import searchIcon from '../assets/search.png';
import fileUploadIcon from '../assets/send.png';

const Sidebar = ({
  sessions = [],
  sessionMessages = {},
  onSelectConversation,
  onNewConsultation,
  loading,
  activeSessionId,  
}) => {
  return (
    <Box
      sx={{
        width: '25%', 
        height: '100%', 
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '16px',
        boxShadow: '0px 4px 8px -2px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
        padding: '16px',
        boxSizing: 'border-box',
        
      }}
    >
      {/* Title Text */}
      <Typography
        sx={{
          fontFamily: 'Inter',
          fontWeight: '600',
          fontSize: '20px',
          lineHeight: '28px',
          color: '#000626',
          marginBottom: '16px',
        }}
      >
        Previous Consultations
      </Typography>

      {/* Search Bar */}
      <TextField
        fullWidth
        placeholder="Search for previous consultations..."
        variant="outlined"
        sx={{
          height: '48px',
          borderRadius: '8px',
          backgroundColor: '#FFFFFF',
          marginBottom: 2,
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            '& fieldset': {
              borderColor: '#000D4D73',
            },
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box component="img" src={searchIcon} alt="Search Icon" sx={{ width: 24, height: 24 }} />
            </InputAdornment>
          ),
        }}
      />

      {/* Start New Consultation Button */}
      <Button
        variant="outlined"
        onClick={onNewConsultation}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '257px',
          height: '40px',
          gap: '8px',
          borderRadius: '8px',
          color: '#4C64D9',
          border: '1px solid #4C64D9',
          textTransform: 'none',
          fontWeight: '500',
          fontSize: '16px',
          mb: 2,
          "&:hover": {
            backgroundColor: '#E3EFFF',
          },
        }}
      >
        <ChatBubbleOutlineIcon />
        Start a new consultation
      </Button>

    
      <Divider sx={{ my: 2, borderBottom: '1px dotted rgba(0, 9, 51, 0.65)' }} />

      {/* Pinned Consultations Section */}
      <Typography
        sx={{
          fontFamily: 'Inter',
          fontWeight: '600',
          fontSize: '20px',
          lineHeight: '28px',
          color: '#000933',
          marginBottom: '16px',
        }}
      >
        Pinned Consultations
      </Typography>

     

      <Divider sx={{ my: 2, borderBottom: '1px dotted rgba(0, 9, 51, 0.65)' }} />

      {/* Recent Consultations Title */}
      <Typography
        sx={{
          fontFamily: 'Inter',
          fontWeight: '600',
          fontSize: '20px',
          lineHeight: '28px',
          color: '#000933',
          marginBottom: '16px',
        }}
      >
        Recent Consultations
      </Typography>

      {/* Conversations List */}
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
        <List>
          {sessions.map((session) => (
            <ListItem
              key={session.session_id}
              button
              onClick={() => onSelectConversation(session.session_id)}
              sx={{
                fontSize: '16px',
                lineHeight: '24px',
                color: '#000D4D',
                pl: 0,
                fontFamily: 'Inter',
                backgroundColor: session.session_id === activeSessionId ? '#E3EFFF' : 'transparent',
                borderRadius: '8px',
                "&:hover": {
                  backgroundColor: '#EFF1FD',
                },
              }}
            >
              <ChatBubbleOutlineIcon
                sx={{
                  fontSize: '18px',
                  marginRight: '8px',
                  color: session.session_id === activeSessionId ? '#4C64D9' : '#000D4D',
                }}
              />
              {sessionMessages[session.session_id]?.slice(0, 30)}...
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Quick Upload Section */}
      <Box
        sx={{
          height: '30%',
       
          backgroundColor: '#EFF1FD',
          borderRadius: '16px',
          border: '2px dashed rgba(76, 100, 217, 0.2)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '16px',
          boxSizing: 'border-box',
          color: '#001580',
          fontSize: '0.875rem',
          textAlign: 'left',
          marginTop: 'auto',
        }}
      >
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ fontWeight: 'bold' }}>Quick Upload</Typography>
          <Link 
            href="#" 
            sx={{ 
              fontSize: '0.875rem', 
              color: '#4C64D9', 
              textDecoration: 'none', 
              fontWeight: '500', 
              marginRight: '5px' 
            }}
          >
            See all files →
          </Link>
        </Box>

        <Box sx={{ textAlign: 'center', color: '#4C64D9', fontSize: '1.2rem', fontWeight: '500', alignSelf: 'center', marginTop: '24px' }}>
          <Box component="img" src={fileUploadIcon} alt="Upload Icon" sx={{ width: 48, height: 48 }} />
        </Box>

        <Typography sx={{ color: '#001580', fontSize: '0.875rem', textAlign: 'center', alignSelf: 'center', marginTop: '8px' }}>
          Drag Files Here <br /> - or - <br /> Click to Upload
        </Typography>

        <Typography 
          sx={{ 
            color: '#A1A1A1', 
            fontSize: '0.75rem', 
            alignSelf: 'flex-start', 
            marginTop: 'auto',
          }}
        >
          no files uploaded for this patient
        </Typography>
      </Box>
    </Box>
  );
};

export default Sidebar;