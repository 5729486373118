import React from 'react';
import { Box, Typography, Button, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import pawLogo from '../assets/Pawlogo.png';

const WelcomePage = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center', 
      minHeight: '100vh', 
      padding: 2, 
      overflow: 'auto',
      backgroundColor: '#F3F5FF'
    }}>
      <Paper 
        elevation={3} 
        sx={{ 
          width: { xs: '90vw', sm: '500px' },  
          maxWidth: '500px',
          height: 'auto',     
          maxHeight: '90vh', 
          padding: 4, 
          borderRadius: '16px',
          textAlign: 'center',
          overflow: 'auto'  
        }}
      >
        {/* Logo */}
        <Box
          component="img"
          src={pawLogo}
          alt="Paw Logo"
          sx={{
            width: '154.53px',
            height: '139.95px',
            marginBottom: '16px',
          }}
        />

        <Typography 
          variant="h4" 
          sx={{ 
            mb: '35px',
            fontSize: '44px',
          }}
        >
          Welcome to PawPilot AI
        </Typography>
        
        <Typography 
          sx={{ 
           
            fontSize: '18px',
            fontWeight: 400,
            lineHeight: 'var(--LineheightSmall)',
            textAlign: 'center',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            mb: 1
          }}
        >
          Please Login or Sign Up to get started
        </Typography>

        {/* Login Button */}
        <Button
          variant="contained"
          onClick={() => navigate('/login')}
          sx={{ 
            mb: 2,
            width: { xs: '90vw', sm: '436px' },
            maxWidth: '436px',
            height: '56px',
            backgroundColor: '#4C64D9',
            borderRadius:'16px',
            color: '#fff',
            textTransform: 'none',
            fontSize: '18px',
            fontWeight: 400,
            lineHeight: 'var(--LineheightHeading4)',
            textAlign: 'right',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            '&:hover': {
              backgroundColor: '#3b50b1',
            }
          }}
        >
          Login
        </Button>

        {/* Sign Up Button */}
        <Button
          variant="contained"
          onClick={() => navigate('/signup')}
          sx={{ 
            width: { xs: '90vw', sm: '436px' },
            maxWidth: '436px',
            height: '56px',
            backgroundColor: '#ffffff',
            color: '#000',
            textTransform: 'none',
            borderRadius:'16px',
            border: '1px solid #4C64D9',
            fontSize: '18px',
            fontWeight: 400,
            lineHeight: 'var(--LineheightHeading4)',
            textAlign: 'right',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            '&:hover': {
              backgroundColor: '#f0f0f0',
            }
          }}
        >
          Sign Up!
        </Button>
      </Paper>
    </Box>
  );
};

export default WelcomePage;
