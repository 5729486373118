import React from 'react';
import { Box, TextField, Select, MenuItem, FormControl, Typography } from '@mui/material';

const PatientForm = ({ formData, setFormData }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '820px',
        padding: 2, 
        boxSizing: 'border-box',
        borderRadius: '12px', 
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        border: '1px solid rgba(0, 17, 102, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5, 
      }}
    >
      {/* Pet Name */}
      <Box sx={{ mb: 1, height: '56px' }}> 
        <Typography sx={{ fontSize: '14px', height: '20px' }}>Pet Name *</Typography>
        <TextField
          fullWidth
          name="pet_name"
          value={formData.pet_name}
          onChange={handleChange}
          required
          variant="outlined"
          InputProps={{
            sx: {
              height: '36px', 
              paddingY: '6px',
            },
          }}
          sx={{
            borderRadius: '6px', 
            mt: '2px',
          }}
        />
      </Box>

      {/* Species and Breed */}
      <Box sx={{ display: 'flex', gap: 1, mb: 1, height: '56px' }}>
        <Box sx={{ flex: 1 }}>
          <Typography sx={{ fontSize: '14px', height: '20px' }}>Species *</Typography>
          <TextField
            fullWidth
            name="species"
            value={formData.species}
            onChange={handleChange}
            required
            variant="outlined"
            InputProps={{
              sx: {
                height: '36px',
                paddingY: '6px',
              },
            }}
            sx={{
              borderRadius: '6px',
              mt: '2px',
            }}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography sx={{ fontSize: '14px', height: '20px' }}>Breed *</Typography>
          <TextField
            fullWidth
            name="breed"
            value={formData.breed}
            onChange={handleChange}
            required
            variant="outlined"
            InputProps={{
              sx: {
                height: '36px',
                paddingY: '6px',
              },
            }}
            sx={{
              borderRadius: '6px',
              mt: '2px',
            }}
          />
        </Box>
      </Box>

      {/* Age, Weight, and Sex */}
      <Box sx={{ display: 'flex', gap: 1, mb: 1, height: '56px' }}>
        <Box sx={{ flex: 1 }}>
          <Typography sx={{ fontSize: '14px', height: '20px' }}>Age *</Typography>
          <TextField
            fullWidth
            name="age"
            type="number"
            value={formData.age}
            onChange={handleChange}
            required
            variant="outlined"
            inputProps={{ min: 0 }}
            InputProps={{
              sx: {
                height: '36px',
                paddingY: '6px',
              },
            }}
            sx={{
              borderRadius: '6px',
              mt: '2px',
            }}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography sx={{ fontSize: '14px', height: '20px' }}>Weight *</Typography>
          <TextField
            fullWidth
            name="weight"
            type="number"
            value={formData.weight}
            onChange={handleChange}
            required
            variant="outlined"
            inputProps={{ min: 0 }}
            InputProps={{
              sx: {
                height: '36px',
                paddingY: '6px',
              },
            }}
            sx={{
              borderRadius: '6px',
              mt: '2px',
            }}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography sx={{ fontSize: '14px', height: '20px' }}>Sex *</Typography>
          <FormControl fullWidth required variant="outlined" sx={{ mt: '2px' }}>
            <Select
              name="sex"
              value={formData.sex}
              onChange={handleChange}
              sx={{
                height: '36px',
                borderRadius: '6px',
              }}
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>

      {/* Major Medical Conditions */}
      <Box sx={{ mb: 1, height: '120px' }}> 
        <Typography sx={{ fontSize: '14px', height: '20px' }}>Major Medical Conditions (Optional)</Typography>
        <TextField
          fullWidth
          name="concerns"
          value={formData.concerns}
          onChange={handleChange}
          multiline
          rows={4} 
          variant="outlined"
          InputProps={{
            sx: {
              height: '80px',
              paddingY: '6px',
            },
          }}
          sx={{
            borderRadius: '6px',
            mt: '2px',
          }}
        />
      </Box>
    </Box>
  );
};

export default PatientForm;
