import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Divider, Paper, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import pawLogo from '../assets/Pawlogo.png';
import googleIcon from '../assets/google.png';
import microsoftIcon from '../assets/microsoft.png';
import appleIcon from '../assets/apple.png';

const LoginPage = ({ onLogin }) => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleOAuthLogin = (provider) => {
    window.location.href = `${process.env.REACT_APP_API_URL}/pawai-backend2/auth/${provider}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/pawai-backend2/auth/login/`, credentials, {
        headers: { 'Content-Type': 'application/json' }
      });
      localStorage.setItem('access_token', response.data.access_token);
      onLogin();
      navigate('/ai-assistant');
    } catch (error) {
      setError('Login failed: ' + (error.response?.data?.detail || error.message));
    }

    setLoading(false);
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
       
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: '420px',
          padding: '32px',
          borderRadius: '16px',
          textAlign: 'center',
          background: '#FFFFFF',
        }}
      >
        {/* Logo */}
        <Box
          component="img"
          src={pawLogo}
          alt="Paw Logo"
          sx={{
            width: '154.53px',
            height: '139.95px',
            marginBottom: '16px',
          }}
        />

        {/* Welcome Back Text */}
        <Typography variant="h4" gutterBottom>
          Welcome back
        </Typography>
        
        {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}
        
        <form onSubmit={handleSubmit}>
          {/* Email Address Input */}
          {!showPassword && (
            <TextField
              label="Email address"
              variant="outlined"
              fullWidth
              value={credentials.username}
              onChange={(e) => setCredentials({ ...credentials, username: e.target.value })}
              onBlur={() => setShowPassword(true)}
              required
              sx={{
                width: '356px',
                height: '56px',
                mb: 2,
                borderRadius: '8px',
              }}
            />
          )}

          {/* Password Input */}
          {showPassword && (
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              value={credentials.password}
              onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
              required
              sx={{
                width: '356px',
                height: '56px',
                mb: 2,
                borderRadius: '8px',
              }}
            />
          )}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
            sx={{
              width: '356px',
              height: '56px',
              mb: 2,
              textTransform: 'none',
              fontSize: '18px',
              fontWeight: 400,
              backgroundColor: '#4C63D9',
              borderRadius: '16px',
              '&:hover': {
                backgroundColor: '#3b50b1',
              },
            }}
          >
            {loading ? <CircularProgress size={24} /> : 'Continue'}
          </Button>

          {/* Sign-up link */}
          <Typography
            variant="body2"
            sx={{ cursor: 'pointer', color: '#000933A6', mt: 2, mb: 1, fontSize: '16px' }}
          >
            Don’t have an account?{' '}
            <Box
              component="span"
              sx={{ color: '#4C64D9', cursor: 'pointer', fontSize: '16px' }}
              onClick={() => navigate('/signup')}
            >
              Sign up
            </Box>
          </Typography>

          <Divider sx={{ my: 2, width: '356px', mx: 'auto' }}>Or</Divider>

          {/* OAuth Buttons */}
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Button
              variant="outlined"
              fullWidth
              sx={{
                width: '356px',
                height: '56px',
                borderRadius: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
                textTransform: 'none',
                color: '#000626E5',
                borderColor: '#000626E5',
                mb: 1,
                fontFamily: 'inter,var(--FontfamilyHeading)', // Font family
                fontSize: 'var(--FontsizeHeading4)',     // Font size
                fontWeight: 400,                         // Font weight
                lineHeight: 'var(--LineheightHeading4)', // Line height
                fontWeight:'400',
              }}
              onClick={() => handleOAuthLogin('google')}
            >
              <Box component="img" src={googleIcon} alt="Google Icon" sx={{ width: 24, height: 24 }} />
              Continue with Google
            </Button>
            <Button
              variant="outlined"
              fullWidth
              sx={{
                width: '356px',
                height: '56px',
                borderRadius: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
                textTransform: 'none',
                color: '#000626E5',
                borderColor: '#000626E5',
                mb: 1,
                fontFamily: 'inter,var(--FontfamilyHeading)', // Font family
                fontSize: 'var(--FontsizeHeading4)',     // Font size
                fontWeight: 400,                         // Font weight
                lineHeight: 'var(--LineheightHeading4)', // Line height
                fontWeight:'400',
              }}
              onClick={() => handleOAuthLogin('microsoft')}
            >
              <Box component="img" src={microsoftIcon} alt="Microsoft Icon" sx={{ width: 24, height: 24 }} />
              Continue with Microsoft
            </Button>
            <Button
              variant="outlined"
              fullWidth
              sx={{
                width: '356px',
                height: '56px',
                borderRadius: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
                textTransform: 'none',
                color: '#000626E5',
                borderColor: '#000626E5',
                fontFamily: 'inter,var(--FontfamilyHeading)', // Font family
                fontSize: 'var(--FontsizeHeading4)',     // Font size
                fontWeight: 400,                         // Font weight
                lineHeight: 'var(--LineheightHeading4)', // Line height
                fontWeight:'400',
              }}
              onClick={() => handleOAuthLogin('apple')}
            >
              <Box component="img" src={appleIcon} alt="Apple Icon" sx={{ width: 24, height: 24 }} />
              Continue with Apple
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default LoginPage;
